import { graphql, Link } from "gatsby"
import * as React from "react"
import Layout from "../../components/global/Layout"
import Seo from "../../components/global/Seo"

export default function LegalList({ data }) {
    
    const frontMatter = data.allSanityLegal

    return (
        <Layout>
          <Seo
            title="Website Legal Policies"
            description="View legal policies for accessing our website."
          />
            <div className="section-large">
                <div className="container text-center">
                    <ul>
                    {frontMatter.nodes.map((node) => {
                        return (
                            <li className="text-lg"><Link to={"/legal/" + node.slug.current}>{node.title}</Link></li>
                        )
                    })}
                    </ul>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
{
  allSanityLegal {
    nodes {
      slug {
        current
      }
      title
    }
  }
}

`